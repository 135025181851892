import type { EquipmentFilter } from 'components/modules/Equipment/Filters/types'
import hasProperty from 'utils/hasProperty'

const isEmptyValue = (val: string) => val === undefined || val === null || val === ''

function filterByProp<OBJ>(filters: EquipmentFilter[]) {
  const filter = (obj: OBJ): boolean => {
    const activeFilters = filters.filter((_) => _.selectedOptions.filter((_) => !isEmptyValue(_))?.length > 0)
    return activeFilters.every((filter) =>
      hasProperty(obj, filter.key)
        ? filter.selectedOptions.includes(obj[filter.key as keyof OBJ]?.toString() || '')
        : false,
    )
  }

  return function filterInternal(obj: OBJ): boolean {
    if (!(filters?.length > 0)) {
      return true
    }

    return filter(obj)
  }
}

export default filterByProp
