import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  VisuallyHidden,
  FormLabel,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import type { FunctionComponent } from 'react'

import CloseIcon from 'assets/close.svg?component'
import SearchIcon from 'assets/search.svg?component'
import type { UseTable } from 'hooks/useTable'

interface TableSearchProps {
  onChange: UseTable['setSearch']
  search: UseTable['search']
}

const TAG = 'TableSearch'

const TableSearch: FunctionComponent<TableSearchProps> = ({ onChange, search }) => {
  const { t } = useTranslation()
  const resetSearch = () => onChange('')

  // TODO: Use FormInput instead to be consistent with the rest of the app
  // @see src\components\modules\Equipment\Details\TabDataExplore\SelectSignals\SignalSearch\index.tsx
  return (
    <Flex as="form" p={6}>
      <InputGroup>
        <VisuallyHidden as={FormLabel}>{t('components.table.search')}</VisuallyHidden>

        <InputLeftElement>
          <Icon as={SearchIcon} aria-hidden="true" color="gray.600" />
        </InputLeftElement>
        <Input
          onChange={onChange}
          value={search}
          placeholder={t('components.table.search')}
          border="none"
          borderRadius="0"
          backgroundColor="gray.100"
          _focusVisible={{ border: 'none' }}
        />
        {!!search && (
          <InputRightElement>
            <IconButton
              data-clear-field
              size="xs"
              variant="ghost"
              color="primary.red"
              icon={<Icon as={CloseIcon} boxSize={3} />}
              aria-label={t('components.table.clear')}
              onClick={resetSearch}
              minH={6}
              _hover={{ bg: 'gray.300' }}
              _focusVisible={{ bg: 'gray.300' }}
              isRound
            />
          </InputRightElement>
        )}
      </InputGroup>
    </Flex>
  )
}

TableSearch.displayName = TAG

export default TableSearch
