import type { TranslateFun } from 'next-translate/useTranslation'

import type { EquipmentFilterKey, FilterOption, EquipmentFilter } from 'components/modules/Equipment/Filters/types'
import { Categories, isCategoryName } from 'config/categories'
import compare from 'utils/compare'

const getFilter = (
  key: EquipmentFilterKey,
  options: string[] | FilterOption[],
  tableFilters: EquipmentFilter[],
): EquipmentFilter => {
  return { key, options, selectedOptions: tableFilters.find((_) => _.key === key)?.selectedOptions || [] }
}

const getBooleanFilter = (
  key: EquipmentFilterKey,
  t: TranslateFun<'common'>,
  tableFilters: EquipmentFilter[],
): EquipmentFilter => {
  return getFilter(
    key,
    [
      { label: t('components.filters.options.yes'), value: 'true' },
      { label: t('components.filters.options.no'), value: 'false' },
    ],
    tableFilters,
  )
}

export function initializeEquipmentFilters(
  tableFilters: EquipmentFilter[],
  t: TranslateFun<'common'>,
): EquipmentFilter[] {
  const filters: EquipmentFilter[] = [
    getBooleanFilter('isSmartDrive', t, tableFilters),
    getBooleanFilter('isConnected', t, tableFilters),
  ]

  const processFilterOptions = Object.keys(Categories)
    .filter(isCategoryName)
    .map((key) => ({ label: t(`components.categories.${key}.title`), value: `${Categories[key]}` }))
    .sort((a, b) => compare(a.label, b.label))
  filters.push(getFilter('process', processFilterOptions, tableFilters))

  return filters
}

export function initializeConnectedEquipmentFilters(
  equipmentList: Database.EquipmentListItemConnected[] | undefined,
  tableFilters: EquipmentFilter[],
  t: TranslateFun<'common'>,
): EquipmentFilter[] {
  const filters: EquipmentFilter[] = []
  filters.push(getBooleanFilter('isSmartDrive', t, tableFilters))

  if (!equipmentList) {
    return filters
  }

  const siteFilterOptions = new Set<string>()
  const makeYearFilterOptions = new Set<string>()
  equipmentList.map((equipment) => {
    if (equipment?.site) {
      siteFilterOptions.add(equipment.site)
    }
    if (equipment?.makeYear) {
      makeYearFilterOptions.add(equipment.makeYear)
    }
  })

  if (siteFilterOptions.size > 0) {
    filters.push(getFilter('site', Array.from(siteFilterOptions), tableFilters))
  }
  if (makeYearFilterOptions.size > 0) {
    filters.push(getFilter('makeYear', Array.from(makeYearFilterOptions), tableFilters))
  }

  return filters
}
